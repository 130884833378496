import { leftPadTo } from "./strings";

export function formatFloat(decimal: number, places: number) {
  if (isNaN(decimal)) return 0;
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${decimal}e+${places}`))}e-${places}`);
}

export function isNumeric(str: string): boolean {
  return /^-?\d+$/.test(str);
}

export const roundToDecimalPlaces = (value: number, decimals: number) =>
  (Math.round(value * 100) / 100).toFixed(decimals);

export default [formatFloat];

/**
 * Formats a dollar amount - adding a dollar sign,
 * ensuring there are exactly 2 decimal places,
 * and adding commas
 * https://stackoverflow.com/questions/5340530/how-to-format-a-dollar
 * @param amount an amount of money as a number
 * @returns a formatted string
 */
export function formatDollarAmount(amount: number, hidePenniesForEvenDollars?: boolean): string {
  const [dollars, cents] = amount.toFixed(2).split(".");

  return (
    "$" +
    dollars
      .split("")
      .reverse()
      .reduce((acc, num, i) => num + (i && !(i % 3) ? "," : "") + acc, "") +
    (cents !== "00" || !hidePenniesForEvenDollars ? `.${cents}` : "")
  );
}

export const addLeadingZeroes = (num: number, minSize: number) => leftPadTo(`${num}`, minSize, "0");

export const isEven = (num: number) => Math.round(num / 2) === num / 2;
export const isOdd = (num: number) => !isEven(num);
